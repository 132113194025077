import { createSlice } from "@reduxjs/toolkit";
import { initialQuery, initialStatus } from "../components/common";

export const initialData = {
  name: "",
  description: "",
  allowed_iterations: "0",
  condition: initialQuery,
  segment_ids: [],
  prevent_concurrent: "0",
  request_type: "edit",
  item_exit_early: 0,
  version_id: 0,
  clicked: false,
  step: 0
};

const initialState = {
  activeWorkFlows: {},
  activeWorkFlowsStatus: initialStatus,
  archiveWorkFlows: {},
  archiveWorkFlowsStatus: initialStatus,
  step: {},
  checkNodeType: "",
  disabledStep: [],
  triggerEventList: null,
  trigger: {},
  triggerStatus: initialStatus,
  tagsList: [],
  tagsListStatus: initialStatus,
  sequences: [],
  sequencesStatus: initialStatus,
  workFlowTrigger: { nodes: [], edges: [] },
  toastStatus: { message: "", color: "", status: "LOADING" },
  disabled: true,
  sequence: {},
  sequenceStatus: initialStatus,
  stepData: {},
  stepDataStatus: initialStatus,
  workflowId: 0,
  httpPostList: [],
  segmentList: [],
  segmentListStatus: initialStatus,
  activeStep: 0,
  completedSteps: {},
  checkCopyStep: false,
  httpPostStatus: initialStatus,
  workFlowData: initialData,
  workFlowCount: 0,
  workFlowStatus: initialStatus,
  workflowTotal: { active: 0, archive: 0 },
  letterTemplates: null,
  letterCategories: null,
  reassignAgreements: null,
  versions: null,
  autoSaveCheck: { path: "/", status: false, changed: false },
};

const reducers = {
  setActiveWorkFlows(state, action) {
    state.activeWorkFlows = action.payload;
  },
  setActiveWorkFlowsStatus(state, action) {
    state.activeWorkFlowsStatus = action.payload;
  },
  setArchiveWorkFlows(state, action) {
    state.archiveWorkFlows = action.payload;
  },
  setArchiveWorkFlowsStatus(state, action) {
    state.archiveWorkFlowsStatus = action.payload;
  },
  setStep(state, action) {
    state.step = action.payload;
  },
  setCheckNodeType(state, action) {
    state.checkNodeType = action.payload;
  },
  setDisabledStep(state, action) {
    state.disabledStep = action.payload
      ? [...state.disabledStep, action.payload]
      : [];
  },
  setSegmentNode(state, action) {
    state.segmentNode = action.payload;
  },
  setTriggerEventList(state, action) {
    state.triggerEventList = action.payload?.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  },
  setTagsList(state, action) {
    state.tagsList = action.payload;
  },
  setTrigger(state, action) {
    state.trigger = action.payload;
  },
  setTriggerStatus(state, action) {
    state.triggerStatus = action.payload;
  },
  setTagsListStatus(state, action) {
    state.tagsListStatus = action.payload;
  },
  setTag(state, action) {
    state.tag = action.payload;
  },
  setTagStatus(state, action) {
    state.tagStatus = action.payload;
  },
  setSequences(state, action) {
    state.sequences = action.payload;
  },
  setSequencesStatus(state, action) {
    state.sequencesStatus = action.payload;
  },
  setSequence(state, action) {
    state.sequence = action.payload;
  },
  setSequenceStatus(state, action) {
    state.sequenceStatus = action.payload;
  },
  setWorkFlowTrigger(state, action) {
    state.workFlowTrigger = { ...state.workFlowTrigger, ...action.payload };
  },
  setToastStatus(state, action) {
    state.toastStatus = action.payload;
  },
  setWorkflowId(state, action) {
    state.workflowId = action.payload;
  },
  setSegmentList(state, action) {
    state.segmentList = action.payload;
  },
  setSegmentListStatus(state, action) {
    state.segmentListStatus = action.payload;
  },
  setActiveStep(state, action) {
    state.activeStep = action.payload;
  },
  setCompletedSteps(state, action) {
    state.completedSteps = action.payload;
  },
  setCheckCopyStep(state, action) {
    state.checkCopyStep = action.payload;
  },
  setHttpPostList(state, action) {
    state.httpPostList = action.payload;
  },
  setHttpPostStatus(state, action) {
    state.httpPostStatus = action.payload;
  },
  setDisabled(state, action) {
    state.disabled = action.payload;
  },
  setWorkFlowData(state, action) {
    state.workFlowData = { ...state.workFlowData, ...action.payload };
  },
  setWorkFlowCount(state, action) {
    state.workFlowCount = action.payload;
  },
  setWorkFlowStatus(state, action) {
    state.workFlowStatus = action.payload;
  },
  setWorkflowTotal(state, action) {
    state.workflowTotal = { ...state.workflowTotal, ...action.payload };
  },
  setLetterTemplates(state, action) {
    const { payload } = action;
    let templates = null;
    const templateType = {
      4: "Email - ",
      2: "Smart - ",
      0: "Static - ",
      8: "Reset Email - ",
      9: "Letter By Reference - ",
      6: "SMS - ",
    };
    if (payload && payload.length > 0) {
      templates = payload.map((template) => ({
        ...template,
        name: `${templateType[template.type]}${template.name}`,
      }));
    }
    state.letterTemplates = templates;
  },
  setLetterCategory(state, action) {
    state.letterCategories = action.payload;
  },
  setReassignAgreements(state, action) {
    state.reassignAgreements = action.payload;
  },
  setVersions(state, action) {
    state.versions = action.payload;
  },
  setAutoSaveCheck(state, action) {
    const { payload } = action;
    state.autoSaveCheck = { ...state.autoSaveCheck, ...payload };
  },
};

const workFlowSlice = createSlice({
  name: "workflows",
  initialState,
  reducers,
});

export const {
  setActiveWorkFlows,
  setActiveWorkFlowsStatus,
  setArchiveWorkFlows,
  setArchiveWorkFlowsStatus,
  setStep,
  setDisabledStep,
  setSegmentNode,
  setTriggerEventList,
  setTriggerStatus,
  setTagsList,
  setTagsListStatus,
  setSequences,
  setSequencesStatus,
  setWorkFlowTrigger,
  setToastStatus,
  setDisabled,
  setSequence,
  setSequenceStatus,
  setWorkflowSteps,
  setWorkflowStepsStatus,
  setStepData,
  setStepDataStatus,
  setWorkflowId,
  setSegmentList,
  setSegmentListStatus,
  setActiveStep,
  setCompletedSteps,
  setCheckCopyStep,
  setHttpPostList,
  setHttpPostStatus,
  setWorkFlowData,
  setWorkFlowCount,
  setCheckNodeType,
  setWorkFlowStatus,
  setWorkflowTotal,
  setLetterTemplates,
  setLetterCategory,
  setReassignAgreements,
  setVersions,
  setAutoSaveCheck,
} = workFlowSlice.actions;
export default workFlowSlice.reducer;
