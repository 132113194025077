import axios, { HttpStatusCode } from "axios";
import { authApiService } from "../../services/auth";

// Constants for storage keys
export const STORAGE_KEY = "accessToken"; // Key for storing the access token
export const COMPANY_ID = "companyId"; // Optional key (for company-specific data)
export const USER_STORAGE_KEY = "currentUser"; // Optional key (for user data)

// Get the API base URL from environment variables
const API_URL = process.env.API_URL;

// Create an Axios instance
const axiosApi = axios.create({
  baseURL: API_URL, // Base URL for all API requests
});

// Function to update the Authorization token in headers dynamically
const updateToken = () => {
  if (typeof window !== "undefined") {
    const token = localStorage.getItem(STORAGE_KEY);
    return token ? `Bearer ${token}` : "";
  }
  return "";
};

// Request interceptor: Attach the Authorization token to each request
axiosApi.interceptors.request.use(
  (config) => {
    const token = updateToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor: Handle 401 errors and refresh tokens
axiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;

    if (
      error.response &&
      error.response.status === 401 && // If 401 Unauthorized error
      !originalConfig._retry // If the request has not been retried yet
    ) {
      originalConfig._retry = true; // Mark the request as retried
      try {
        // Attempt to refresh the token
        const refreshResponse = await axios.post(`${API_URL}/refresh`, {}, {
          headers: {
            Authorization: `Bearer ${updateToken()}`, // Attach the old access token for refresh
          },
        });

        // Save the new token in localStorage
        const newAccessToken = refreshResponse.data.results.authorisation.token;
        localStorage.setItem(STORAGE_KEY, newAccessToken);

        // Update the original request with the new token and retry it
        originalConfig.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return axiosApi(originalConfig); // Retry the original request
      } catch (refreshError) {
        // If refresh token fails, log out the user
        if (
          refreshError.response &&
          (refreshError.response.status === HttpStatusCode.Unauthorized ||
            refreshError?.response?.data?.message === "Could not refresh token.")
        ) {
          authApiService.logout(); // Handle logout logic here
        }
        return Promise.reject(refreshError);
      }
    }

    // If the error is not 401 or the retry fails, reject the error
    return Promise.reject(error);
  }
);

// Generic GET request
export async function get(url, config = {}) {
  return axiosApi.get(url, { ...config }).then((response) => response.data);
}

// Generic POST request
export async function post(url, data, config = {}) {
  return axiosApi.post(url, data, config).then((response) => response.data);
}

// Generic PUT request
export async function put(url, data, config = {}) {
  return axiosApi.put(url, data, config).then((response) => response.data);
}

// Generic PATCH request
export async function pat(url, data, config = {}) {
  return axiosApi.patch(url, data, config).then((response) => response.data);
}

// Generic DELETE request
export async function del(url, config = {}) {
  return axiosApi.delete(url, config).then((response) => response.data);
}
