import { createSlice } from "@reduxjs/toolkit";
import { initialStatus } from "../components/common";

const initialState = {
  usersList: {},
  usersListStatus: initialStatus,
  userDetails: {},
  userDetailsStatus: initialStatus,
  roleListing: null,
  toastStatus: { message: "", color: "", status: "LOADING" },
};

const reducers = {
  setUsersList(state, action) {
    state.usersList = action.payload;
  },
  setUsersListStatus(state, action) {
    state.usersListStatus = action.payload;
  },
  setUserDetails(state, action) {
    state.userDetails = action.payload;
  },
  setUserDetailsStatus(state, action) {
    state.userDetailsStatus = action.payload;
  },
  setRoleListing(state, action) {
    state.roleListing = action.payload;
  },
  setToastStatus(state, action) {
    state.toastStatus = action.payload;
  },
};

const managementSlice = createSlice({
  name: "managements",
  initialState,
  reducers,
});

export const {
  setUsersList,
  setUsersListStatus,
  setUserDetails,
  setUserDetailsStatus,
  setMenuList,
  setSingleRole,
  setSingleRoleStatus,
  setRoleListing,
  setToastStatus,
} = managementSlice.actions;
export default managementSlice.reducer;
