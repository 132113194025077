import { alpha } from "@mui/system/colorManipulator";
import { backdropClasses } from "@mui/material/Backdrop";
import { filledInputClasses } from "@mui/material/FilledInput";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { paperClasses } from "@mui/material/Paper";
import { tableCellClasses } from "@mui/material/TableCell";
import { common } from "@mui/material/colors";

export const createComponents = ({ palette }) => {
  const themeStyle = () => {
    if (palette.mode === "dark") {
      return {
        AvatarBg: palette.neutral[300],
        BackdropBg: alpha(common.black, 0.50),
        CardBoxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.08), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.06)",
        ChipBorderColor: palette.neutral[700],
        Input: palette.divider,
        TableHeadBg: palette.neutral[800],
        TableHeadColor: palette.neutral[400],
      };
    }
    if (palette.mode === "light") {
      return {
        AvatarBg: palette.neutral[200],
        BackdropBg: alpha(palette.neutral[900], 0.75),
        CardBoxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
        ChipBorderColor: palette.neutral[200],
        Input: palette.neutral[200],
        TableHeadBg: palette.neutral[50],
        TableHeadColor: palette.neutral[700],
      };
    }
  };

  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: themeStyle().AvatarBg,
          color: common.black,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          [`&:not(.${backdropClasses.invisible})`]: {
            backgroundColor: themeStyle().BackdropBg,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          [`&.${paperClasses.elevation1}`]: {
            boxShadow: themeStyle().CardBoxShadow,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        icon: {
          color: palette.action.active,
        },
        root: {
          borderColor: themeStyle().ChipBorderColor,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "#nprogress .bar": {
          backgroundColor: palette.primary.main,
        },
        ".slick-dots li button": {
          "&:before": {
            fontSize: 10,
            color: palette.primary.main,
          },
        },
        ".slick-dots li.slick-active button": {
          "&:before": {
            color: palette.primary.main,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            color: palette.text.secondary,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderColor: themeStyle().Input,
          "&:hover": {
            backgroundColor: palette.action.hover,
          },
          [`&.${filledInputClasses.disabled}`]: {
            backgroundColor: "transparent",
          },
          [`&.${filledInputClasses.focused}`]: {
            backgroundColor: "transparent",
            borderColor: palette.primary.main,
            // boxShadow: `${palette.primary.main} 0 0 0 2px`,
          },
          [`&.${filledInputClasses.error}`]: {
            borderColor: palette.error.main,
            // boxShadow: `${palette.error.main} 0 0 0 2px`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: palette.action.hover,
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: themeStyle().Input,
            },
          },
          [`&.${outlinedInputClasses.focused}`]: {
            backgroundColor: "transparent",
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: palette.primary.main,
              // boxShadow: `${palette.primary.main} 0 0 0 2px`,
            },
          },
          [`&.${filledInputClasses.error}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: palette.error.main,
              // boxShadow: `${palette.error.main} 0 0 0 2px`,
            },
          },
        },
        notchedOutline: {
          borderColor: themeStyle().Input,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: palette.neutral[500],
        },
        track: {
          backgroundColor: palette.neutral[400],
          opacity: 1,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: palette.divider,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          [`& .${tableCellClasses.root}`]: {
            backgroundColor: themeStyle().TableHeadBg,
            color: themeStyle().TableHeadColor,
          },
        },
      },
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          backgroundColor: palette.divider,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backdropFilter: "blur(6px)",
          background: alpha(palette.neutral[900], 0.8),
        },
      },
    },
  };
};
