import { createSlice } from "@reduxjs/toolkit";
import { initialStatus } from "../components/common";
const initialState = {
  toastStatus: { message: "", color: "", status: "LOADING" },
  taskList: {},
  taskListStatus: initialStatus,
  taskTagList: [],
  taskTagStatus: initialStatus,
  usersList: [],
  associatedClients: null,
  viewByTaskTagsList: [],
  myTaskList: {},
  myTaskListStatus: initialStatus,
  manageTagsList: {},
  manageTagsListStatus: initialStatus,
  allTaskTags: null,
  allTaskTagsStatus: initialStatus,
  taskCategoryList: {},
  taskCategoryListStatus: initialStatus,
  categories: null,
  taskActivities: {},
  taskActivitiesStatus: initialStatus,
  taskDetails: [],
  taskDetailsStatus: initialStatus,
  allTags: null,
  allCategory: null,
};

const reducers = {
  setTaskList(state, action) {
    const { payload } = action;
    state.taskList = payload;
  },
  setTaskStatus(state, action) {
    const { payload } = action;
    state.taskListStatus = payload;
  },
  setTaskTagList(state, action) {
    const { payload } = action;
    state.taskTagList = payload;
  },
  setTaskTagStatus(state, action) {
    const { payload } = action;
    state.taskTagStatus = payload;
  },
  setUsersList(state, action) {
    const { payload } = action;
    state.usersList = payload.sort((a, b) =>
      a.first_name.localeCompare(b.first_name)
    );
  },
  setAssociatedClients(state, action) {
    const { payload } = action;
    state.associatedClients = payload;
  },
  setViewByTaskTagsList(state, action) {
    const { payload } = action;
    state.viewByTaskTagsList = payload;
  },
  setToastStatus(state, action) {
    const { payload } = action;
    state.toastStatus = payload;
  },
  setMyTaskList(state, action) {
    const { payload } = action;
    state.myTaskList = payload;
  },
  setMyTaskListStatus(state, action) {
    const { payload } = action;
    state.myTaskListStatus = payload;
  },
  setManageTagsList(state, action) {
    const { payload } = action;
    state.manageTagsList = payload;
  },
  setManageTagsListStatus(state, action) {
    const { payload } = action;
    state.manageTagsListStatus = payload;
  },
  setAllTaskTags(state, action) {
    const { payload } = action;
    state.allTaskTags = payload;
  },
  setAllTaskTagsStatus(state, action) {
    const { payload } = action;
    state.allTaskTagsStatus = payload;
  },
  setTaskCategoryList(state, action) {
    const { payload } = action;
    state.taskCategoryList = payload;
  },
  setTaskCategoryStatus(state, action) {
    const { payload } = action;
    state.taskCategoryListStatus = payload;
  },
  setCategories(state, action) {
    const { payload } = action;
    state.categories = payload;
  },
  setTaskActivities(state, action) {
    const { payload } = action;
    state.taskActivities = payload;
  },
  setTaskActivitiesStatus(state, action) {
    const { payload } = action;
    state.taskActivitiesStatus = payload;
  },
  setTaskDetails(state, action) {
    const { payload } = action;
    state.taskDetails = payload;
  },
  setTaskDetailsStatus(state, action) {
    const { payload } = action;
    state.taskDetailsStatus = payload;
  },
  setAllTags(state, action) {
    const { payload } = action;
    state.allTags = payload;
  },
  setAllCategory(state, action) {
    const { payload } = action;
    state.allCategory = payload;
  },
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers,
});

export const {
  setToastStatus,
  setTaskList,
  setTaskTagList,
  setTaskTagStatus,
  setUsersList,
  setAssociatedClients,
  setViewByTaskTagsList,
  setTaskStatus,
  setMyTaskList,
  setMyTaskListStatus,
  setManageTagsList,
  setManageTagsListStatus,
  setAllTaskTags,
  setAllTaskTagsStatus,
  setTaskCategoryList,
  setTaskCategoryStatus,
  setCategories,
  setTaskActivities,
  setTaskActivitiesStatus,
  setTaskDetails,
  setTaskDetailsStatus,
  setAllTags,
  setAllCategory,
} = taskSlice.actions;
export default taskSlice.reducer;
