import { createSlice } from "@reduxjs/toolkit";
import { initialStatus } from "../components/common";
const initialState = {
  toastStatus: { errors: "", message: "", color: "", status: "LOADING" },
  reportList: {},
  bureauList: {},
  instructionsList: {},
  claimCodeList: [],
  reportListStatus: initialStatus,
  contactList: {},
  contactListStatus: initialStatus,
  bureauListStatus: initialStatus,
  instructionsListStatus: initialStatus,
  claimCodeListStatus: initialStatus,
  accountResponsibilities: null,
  accountResponsibilityList: {},
  accountResponsibilityListStatus: initialStatus,
  accountTypes: null,
  accountTypeList: {},
  accountTypeListStatus: initialStatus,
  accountStatus: null,
  accountStatusList: {},
  accountStatusListStatus: initialStatus,
  publicRecordTypes: null,
  publicRecordTypeList: {},
  publicRecordTypeListStatus: initialStatus,
  publicRecordStatus: null,
  publicRecordStatusList: {},
  publicRecordStatusListStatus: initialStatus,
  attestationSections: null,
  attestationSectionList: {},
  attestationSectionListStatus: initialStatus,
  collectionStatus: null,
  collectionStatusList: {},
  collectionStatusListStatus: initialStatus,
  faxSettingList: {},
  faxSettingListStatus: initialStatus,
  accountFurnishers: null,
  furnisherEdit: null,
  bureauEdit: null,
  creditRepairDetails: {},
  creditRepairDetailsStatus: initialStatus,
  otherContractsList: {},
  otherContractsListStatus: initialStatus,
  otherContractsDetails: {},
  otherContractsDetailsStatus: initialStatus,
  snippitsContractDetails: {},
  snippitsContractDetailsStatus: initialStatus,
  stateSnippits: {},
  stateSnippitsStatus: initialStatus,
  stateSnippitDetails: {},
  stateSnippitDetailsStatus: initialStatus,
  billingStatusList: null,
  brandsList: {},
  brandsListStatus: initialStatus,
  printsList: {},
  printsStatus: initialStatus,
  batchPrintsList: {},
  batchPrintsStatus: initialStatus,
  pendingBatchList: {},
  pendingBatchStatus: initialStatus,
  stateContractSnippits: {},
  stateContractSnippitsStatus: initialStatus,
  agreementSnippits: {},
  agreementSnippitsStatus: initialStatus,
  instructions: null,
};

const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setReportList: (state, action) => {
      const { payload } = action;
      state.reportList = payload;
    },
    setReportStatus: (state, action) => {
      const { payload } = action;
      state.reportListStatus = payload;
    },
    setBureauList: (state, action) => {
      const { payload } = action;
      state.bureauList = payload;
    },
    setContactList: (state, action) => {
      const { payload } = action;
      state.contactList = payload;
    },
    setContactStatus: (state, action) => {
      const { payload } = action;
      state.contactListStatus = payload;
    },
    setInstructionsList: (state, action) => {
      const { payload } = action;
      state.instructionsList = payload;
    },
    setInstructions: (state, action) => {
      const { payload } = action;
      state.instructions = payload;
    },
    setInstructionsStatus: (state, action) => {
      const { payload } = action;
      state.instructionsListStatus = payload;
    },
    setClaimCodeList: (state, action) => {
      const { payload } = action;
      state.claimCodeList = payload;
    },
    setClaimCodeStatus: (state, action) => {
      const { payload } = action;
      state.claimCodeListStatus = payload;
    },
    setBureauStatus: (state, action) => {
      const { payload } = action;
      state.bureauListStatus = payload;
    },
    setResponsibilities: (state, action) => {
      const { payload } = action;
      state.accountResponsibilities = payload;
    },
    setResponsibilityList: (state, action) => {
      const { payload } = action;
      state.accountResponsibilityList = payload;
    },
    setAccountResponsibilityStatus: (state, action) => {
      const { payload } = action;
      state.accountResponsibilityListStatus = payload;
    },
    setAccountTypes: (state, action) => {
      const { payload } = action;
      state.accountTypes = payload;
    },
    setAccountTypeList: (state, action) => {
      const { payload } = action;
      state.accountTypeList = payload;
    },
    setAccountTypeStatus: (state, action) => {
      const { payload } = action;
      state.accountTypeListStatus = payload;
    },
    setAccountStatus: (state, action) => {
      const { payload } = action;
      state.accountStatus = payload;
    },
    setAccountStatusList: (state, action) => {
      const { payload } = action;
      state.accountStatusList = payload;
    },
    setAccountStatusListStatus: (state, action) => {
      const { payload } = action;
      state.accountStatusListStatus = payload;
    },
    setPublicRecordTypes: (state, action) => {
      const { payload } = action;
      state.publicRecordTypes = payload;
    },
    setPublicRecordTypeList: (state, action) => {
      const { payload } = action;
      state.publicRecordTypeList = payload;
    },
    setPublicRecordTypeListStatus: (state, action) => {
      const { payload } = action;
      state.publicRecordTypeListStatus = payload;
    },
    setPublicRecordStatus: (state, action) => {
      const { payload } = action;
      state.publicRecordStatus = payload;
    },
    setPublicRecordStatusList: (state, action) => {
      const { payload } = action;
      state.publicRecordStatusList = payload;
    },
    setPublicRecordStatusListStatus: (state, action) => {
      const { payload } = action;
      state.publicRecordStatusListStatus = payload;
    },
    setAttestationSections: (state, action) => {
      const { payload } = action;
      state.attestationSections = payload;
    },
    setAttestationSectionList: (state, action) => {
      const { payload } = action;
      state.attestationSectionList = payload;
    },
    setAttestationSectionListStatus: (state, action) => {
      const { payload } = action;
      state.attestationSectionListStatus = payload;
    },
    setCollectionStatus: (state, action) => {
      const { payload } = action;
      state.collectionStatus = payload;
    },
    setCollectionStatusList: (state, action) => {
      const { payload } = action;
      state.collectionStatusList = payload;
    },
    setCollectionStatusListStatus: (state, action) => {
      const { payload } = action;
      state.collectionStatusListStatus = payload;
    },
    setFaxSettingList: (state, action) => {
      const { payload } = action;
      state.faxSettingList = payload;
    },
    setFaxSettingListStatus: (state, action) => {
      const { payload } = action;
      state.faxSettingListStatus = payload;
    },
    setAccountFurnishers: (state, action) => {
      const { payload } = action;
      state.accountFurnishers = payload;
    },
    setFurnisherEdit: (state, action) => {
      const { payload } = action;
      state.furnisherEdit = payload;
    },
    setBureauEdit: (state, action) => {
      const { payload } = action;
      state.bureauEdit = payload;
    },
    setCreditRepairDetails: (state, action) => {
      const { payload } = action;
      state.creditRepairDetails = payload;
    },
    setCreditRepairDetailsStatus: (state, action) => {
      const { payload } = action;
      state.creditRepairDetailsStatus = payload;
    },
    setOtherContractsList: (state, action) => {
      const { payload } = action;
      state.otherContractsList = payload;
    },
    setOtherContractsListStatus: (state, action) => {
      const { payload } = action;
      state.otherContractsListStatus = payload;
    },
    setOtherContractsDetails: (state, action) => {
      const { payload } = action;
      state.otherContractsDetails = payload;
    },
    setOtherContractsDetailsStatus: (state, action) => {
      const { payload } = action;
      state.otherContractsDetailsStatus = payload;
    },
    setSnippitsContractDetails: (state, action) => {
      const { payload } = action;
      state.snippitsContractDetails = payload;
    },
    setSnippitsContractDetailsStatus: (state, action) => {
      const { payload } = action;
      state.snippitsContractDetailsStatus = payload;
    },
    setStateSnippits: (state, action) => {
      const { payload } = action;
      state.stateSnippits = payload;
    },
    setStateSnippitsStatus: (state, action) => {
      const { payload } = action;
      state.stateSnippitsStatus = payload;
    },
    setStateSnippitDetails: (state, action) => {
      const { payload } = action;
      state.stateSnippitDetails = payload;
    },
    setStateSnippitDetailsStatus: (state, action) => {
      const { payload } = action;
      state.stateSnippitDetailsStatus = payload;
    },
    setBillingStatusList: (state, action) => {
      const { payload } = action;
      state.billingStatusList = payload;
    },
    setToastStatus: (state, action) => {
      const { payload } = action;
      state.toastStatus = payload;
    },
    setBrandsList: (state, action) => {
      const { payload } = action;
      state.brandsList = payload;
    },
    setBrandsListStatus: (state, action) => {
      const { payload } = action;
      state.brandsListStatus = payload;
    },
    setPrintsList: (state, action) => {
      const { payload } = action;
      state.printsList = payload;
    },
    setPrintsStatus: (state, action) => {
      const { payload } = action;
      state.printsStatus = payload;
    },
    setBatchPrintsList: (state, action) => {
      const { payload } = action;
      state.batchPrintsList = payload;
    },
    setBatchPrintsStatus: (state, action) => {
      const { payload } = action;
      state.batchPrintsStatus = payload;
    },
    setPendingBatchList: (state, action) => {
      const { payload } = action;
      state.pendingBatchList = payload;
    },
    setPendingBatchStatus: (state, action) => {
      const { payload } = action;
      state.pendingBatchStatus = payload;
    },
    setStateContractSnippit: (state, action) => {
      const { payload } = action;
      state.stateContractSnippits = payload;
    },
    setStateContractSnippitStatus: (state, action) => {
      const { payload } = action;
      state.stateContractSnippitsStatus = payload;
    },
    setAgreementSnippit: (state, action) => {
      const { payload } = action;
      state.agreementSnippits = payload;
    },
    setAgreementSnippitStatus: (state, action) => {
      const { payload } = action;
      state.agreementSnippitsStatus = payload;
    },
  },
});

export const {
  setToastStatus,
  setReportList,
  setReportStatus,
  setBureauList,
  setBureauStatus,
  setContactList,
  setContactStatus,
  setInstructions,
  setInstructionsList,
  setInstructionsStatus,
  setClaimCodeList,
  setClaimCodeStatus,
  setResponsibilities,
  setResponsibilityList,
  setAccountResponsibilityStatus,
  setAccountTypes,
  setAccountTypeList,
  setAccountTypeStatus,
  setAccountStatus,
  setAccountStatusList,
  setAccountStatusListStatus,
  setPublicRecordTypes,
  setPublicRecordTypeList,
  setPublicRecordTypeListStatus,
  setPublicRecordStatus,
  setPublicRecordStatusList,
  setPublicRecordStatusListStatus,
  setAttestationSections,
  setAttestationSectionList,
  setAttestationSectionListStatus,
  setCollectionStatus,
  setCollectionStatusList,
  setCollectionStatusListStatus,
  setFaxSettingList,
  setFaxSettingListStatus,
  setAccountFurnishers,
  setFurnisherEdit,
  setBureauEdit,
  setCreditRepairDetails,
  setCreditRepairDetailsStatus,
  setOtherContractsList,
  setOtherContractsListStatus,
  setOtherContractsDetails,
  setOtherContractsDetailsStatus,
  setSnippitsContractDetails,
  setSnippitsContractDetailsStatus,
  setStateSnippits,
  setStateSnippitsStatus,
  setStateSnippitDetails,
  setStateSnippitDetailsStatus,
  setBillingStatusList,
  setBrandsList,
  setBrandsListStatus,
  setPrintsList,
  setPrintsStatus,
  setBatchPrintsList,
  setBatchPrintsStatus,
  setPendingBatchList,
  setPendingBatchStatus,
  setStateContractSnippit,
  setStateContractSnippitStatus,
  setAgreementSnippit,
  setAgreementSnippitStatus,
} = systemSlice.actions;
export default systemSlice.reducer;
